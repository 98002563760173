import { Trans } from '@lingui/macro';
import React, { FC } from 'react';
import Image from '../Image/Image';
import { CardIconProps } from './CardIcon.model';

export const CardIcon: FC<CardIconProps> = ({
  iconName,
  title,
  subTitle,
  className = 'flex flex-col px-4 shadow-cardNew',
  iconClassName = 'w-10',
}) => {
  return (
    <div className={`${className} items-start py-4 gap-4 rounded-lg`}>
      <div className={`bg-primary p-2 rounded-lg ${iconClassName} min-w-10 mb-4`}>
        <Image name={iconName} />
      </div>
      <div className="flex flex-col">
        <h3 className="text-lg font-bold mb-4">{title}</h3>
        <p>{subTitle}</p>
      </div>
    </div>
  );
};
