import { Trans } from '@lingui/macro';
import Button from 'components/atoms/Button/Button';
import { CardIcon } from 'components/atoms/CardIcon/CardIcon';
import { IconFA } from 'components/atoms/IconFA/IconFA';
import React, { FC } from 'react';
import { ElevateDevelopmentGameSectionProps } from './ElevateDevelopmentGameSection.model';

export const ElevateDevelopmentGameSection: FC<ElevateDevelopmentGameSectionProps> = ({ id }) => {
  return (
    <section className="flex flex-col justify-center items-center mt-40 m-auto px-9" id={id}>
      <div className="text-center mb-6">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
          <Trans>Looking to elevate your development game?</Trans>
        </h2>

        <div className="flex justify-center">
          <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[43rem]">
            <Trans>
              Revolutionize your software development with our secure, flexible, and collaborative
              platform.
            </Trans>
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 max-w-[60rem]">
        <CardIcon
          iconName="elevate-development-game-section/cost-reduction"
          title="30% Cost Reduction"
          subTitle="Streamline development workflows and eliminates bottlenecks, resulting in significant
              infrastructure cost savings for enterprises."
        />
        <CardIcon
          iconName="elevate-development-game-section/infrastructure-code"
          title="10x Infrastructure Code Reduction"
          subTitle="Writing and maintaining infrastructure as code is simplified, freeing up valuable developer time and resources."
        />
        <CardIcon
          iconName="elevate-development-game-section/multi-cloud"
          title="Multi-Cloud Support"
          subTitle="Enterprises can leverage the power of multiple cloud vendors, including GCP, Azure, AWS, and private Kubernetes setups."
        />
        <CardIcon
          iconName="elevate-development-game-section/cloud-service"
          title="Full Spectrum Cloud Services"
          subTitle="Fractal Cloud supports FaaS, PaaS, CaaS, and IaaS at an agnostic level, making it easy for enterprises to adopt cloud-native solutions."
        />
      </div>

      <div className="mt-9 hidden md:block">
        <a href="#pricing_cards">
          <Button kind="Primary" className="w-full text-nowrap py-2">
            <div className="flex items-center gap-3">
              <span>Discover Fractal Cloud</span>
              <IconFA icon="arrow-right" />
            </div>
          </Button>
        </a>
      </div>
    </section>
  );
};
