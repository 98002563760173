import { Trans } from '@lingui/macro';
import Image from 'components/atoms/Image/Image';
import React, { FC } from 'react';
import { OurHistoryVisionProps } from './OurHistoryVision.model';

export const OurHistoryVision: FC<OurHistoryVisionProps> = ({ id }) => {
  return (
    <section
      className="flex flex-col items-center justify-center max-w-[70rem] h-fit m-auto mt-64"
      id={id}
    >
      <div className="text-center">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text mb-4">
          <Trans>Our History and Vision</Trans>
        </h2>
        <p className="text-[24px] font-[500] text-base-300 leading-[175%] max-w-[43rem]">
          From Sicily to Global Innovation
        </p>
      </div>

      <div className="w-full items-center flex flex-col lg:flex-row">
        <Image name="our-history-vision-section/our-history-vision" />
        <div className="mx-10 lg:max-w-[30rem] flex flex-col justify-center items-center">
          <p>
            <Trans>
              YanchWare is a multinational company with offices in Italy and Denmark, specializing
              in Cloud Computing and Cybersecurity. Our diverse, international team consists of
              developers, visual designers and marketers, all working together to deliver
              cutting-edge solutions.
            </Trans>
          </p>
          <p>
            <Trans>
              We are committed to providing businesses with the tools, services, and training they
              need to achieve their goals and thrive independently in today’s dynamic business
              landscape. At YanchWare, our journey is as unique as the innovations we bring to the
              cloud computing industry.
            </Trans>
          </p>
        </div>
      </div>
    </section>
  );
};
