import { Trans } from '@lingui/macro';
import Image from 'components/atoms/Image/Image';
import React, { FC } from 'react';
import { CompanyWorkWithUsSectionProps } from './CompanyWorkWithUsSection.model';

export const CompanyWorkWithUsSection: FC<CompanyWorkWithUsSectionProps> = ({ title }) => {
  return (
    <section className="flex max-w-[1280px] mt-40 m-auto items-stretch">
      <div className="worked-with">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
          {title}
        </h2>
        <div className="company-icons">
          <Image name="stark" className="w-[140px] tablet-up:w-[200px]" />
          <Image name="targit" className="w-[140px] tablet-up:w-[200px]" />
          <Image name="danske-bank" className="w-[140px] tablet-up:w-[200px]" />
          <Image name="schibsted" className="w-[140px] tablet-up:w-[200px]" />
          <Image name="bestseller" className="w-[140px] tablet-up:w-[200px]" />
          <Image name="aarhus-university" className="w-[140px] tablet-up:w-[200px]" />
          <Image name="ebay" className="w-[140px] tablet-up:w-[200px]" />
          <Image name="unicef" className="w-[140px] tablet-up:w-[200px]" />
        </div>
      </div>
    </section>
  );
};
