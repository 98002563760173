import { Trans } from '@lingui/macro';
import { CardIcon } from 'components/atoms/CardIcon/CardIcon';
import React from 'react';

export const GreatPrideSection = () => {
  return (
    <section className="flex flex-col justify-center items-center mt-64 m-auto px-9">
      <div className="text-center mb-6">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
          <Trans>We take great pride in</Trans>
        </h2>
      </div>
      <div className="flex flex-col gap-6 max-w-[50rem]">
        <CardIcon
          iconName="great-pride-section/legacy-competitive"
          title="Turning Legacy into Competitive Advantage"
          subTitle="Revolutionize Mainframe ERP systems into lightning-fast event sources, streaming real-time data. Empower cloud microservices, eliminate overnight processes. Unleash the power of legacy systems for business growth."
        />
        <CardIcon
          iconName="great-pride-section/revenue-streams"
          title="Creating New Revenue Streams"
          subTitle="Seize innovation's potential. We've reimagined on-prem products as scalable SaaS solutions, fueling new revenue streams for our clients. Unlock hidden potential and propel your business to new heights with our expert guidance."
        />
        <CardIcon
          iconName="great-pride-section/customer-succeed"
          title="Helping Our Customers Succeed"
          subTitle="Fuel your growth with comprehensive solutions: Cloud Native transformations, automated infrastructure, security, training, and more. Conquer the cloud landscape, achieve efficiency, elevate your success. Let's reach unprecedented heights together."
        />
      </div>
    </section>
  );
};
