import { Trans } from '@lingui/macro';
import { CardIcon } from 'components/atoms/CardIcon/CardIcon';
import React from 'react';

export const SustainableGoalsSection = () => {
  return (
    <section className="flex flex-col justify-center items-center mt-64 m-auto px-9">
      <div className="text-center mb-6">
        <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
          <Trans>Sustainable Development Goals</Trans>
        </h2>
        <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[80rem]">
          <Trans>
            Our commitment extends beyond profits; we are champions of positive change. By aligning
            ourselves with the United Nations' Sustainable Development Goals, we actively contribute
            to building a better world.
          </Trans>
        </p>
      </div>

      <div className="flex flex-col lg:flex-row justify-between gap-4 max-w-[80rem]">
        <CardIcon
          iconName="sustainable-goals-section/quality-education"
          title="Quality Education"
          subTitle="Empower all with inclusive, quality education and lifelong learning for a brighter future."
          className="flex flex-col items-center px-4 shadow-cardNew text-center"
          iconClassName="w-16 p-4"
        />
        <CardIcon
          iconName="sustainable-goals-section/economical-growth"
          title="Economical Growth"
          subTitle="Drive sustainable, inclusive economic growth with full employment and decent work for all."
          className="flex flex-col items-center px-4 shadow-cardNew text-center"
          iconClassName="w-16 p-4"
        />
        <CardIcon
          iconName="sustainable-goals-section/sustainable-comunities"
          title="Sustainable Comunities"
          subTitle="Make cities and human settlements inclusive, safe, resilient and sustainable."
          className="flex flex-col items-center px-4 shadow-cardNew text-center"
          iconClassName="w-16 p-4"
        />
      </div>
    </section>
  );
};
