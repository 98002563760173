import { Trans } from '@lingui/macro';
import Image from 'components/atoms/Image/Image';
import React from 'react';

export const ThankYouSection = () => {
  const trackConversion = () => {
    const value = 1.0;
    const currency = 'EUR';
    const transactionId = 'transaction_id';

    window.gtag('event', 'conversion', {
      send_to: 'AW-16690490685/Ia9fCNubvNMZEL3a0pY-',
      value: value,
      currency: currency,
      transaction_id: transactionId,
    });
  };

  trackConversion();

  return (
    <section className="w-full gap-14 my-72">
      <div className="relative flex flex-col items-center text-center mb-6">
        <div className="flex items-center justify-center border-2 border-gray-100 rounded-full p-12">
          <div className="flex items-center justify-center border-2 border-base-100 rounded-full p-6">
            <div className="w-20">
              <Image name="check-mark-grey" />
            </div>
          </div>
        </div>

        <div className="absolute bottom-[-2rem]">
          <h2 className="text-[34px] font-[500] text-transparent bg-clip-text bg-hero-text">
            <Trans>Thank you for reaching out!</Trans>
          </h2>
          <div className="flex justify-center">
            <p className="text-[16px] md:text-[24px] font-[500] text-base-300 leading-[175%] max-w-[43rem]">
              You will get a response as soon as possible!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};
